require('../datatable.js')

$(document).on("turbolinks:load", function() {
  $('#roles__search-wrap').text("");
  $('#roles__pagination-wrap').text("");
  $('.roles-container').datatable({
    search: true,
    searchSelector: '#roles__search-wrap',
    namePlural: $('.roles-container').attr("plural"),
    nameSingular: $('.roles-container').attr("singular"),
    searchPlaceholder: "Search",
    pagingSelector: '#roles__pagination-wrap',
    elementSelector: '.hierarchy-row--toplevel',
    rootnode: '.roles-wrap',
    isTable: false,
    elementsPerPage: 10
  });
});
